<template>
    <div class="preview_area" v-if="modelValue">
        <button class="close_btn pointer" @click="closeModal()"><i class="fas fa-times"></i></button>
        <habit-report :habit-tracking="habitTracking" :contact-relation="contactRelation"/>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'

    const HabitReport = defineAsyncComponent(() => import('@/pages/habit-tracking/components/HabitReport'))

    export default {
        name: 'View Habit Report',

        props: {
            modelValue: Boolean,
            habitTracking: Object,
            contactRelation: Object,
        },

        emits: ['update:modelValue'],

        components: {
            HabitReport,
        },

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },
        },
    }
</script>

<style scoped>
    .preview_area .close_btn {
        right: 15px;
        top: 15px;
        left: auto;
        position: fixed;
        background: #eaeaea;
        width:25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
    }

    .modal .preview_area.show_sidebar {
        left: 0;
        width: 100%;
    }
</style>
